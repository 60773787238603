import { createQueryKeys } from '@lukemorales/query-key-factory'
import {
  type IncludableField,
  type ParametersGetAll,
  type ParametersGetAllSharedWithMeUnpaginated,
  type ParametersGetAllUnpaginated,
  responseDataTypeName,
  useFetchFolder,
} from './useFetchFolder'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

// stale time of 0 is required as long as we still have places that mutate
// folders without invalidating the cache (useApi)
const DEFAULT_STALE_TIME = 0

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    workspaceId,
    fieldsToInclude = [],
    params,
  }:
  {
    workspaceId: MaybeRef<string>
    fieldsToInclude?: string[]
    params?: MaybeRef<ParametersGetAll>
  },
  ) => ({
    queryKey: [
      workspaceId,
      ...(fieldsToInclude ? [fieldsToInclude.sort()] : []),
      ...(params ? [params] : []),
    ],
  }),

  allUnpaginated: ({
    workspaceId,
    fieldsToInclude = [],
    params,
  }: {
    workspaceId: MaybeRef<string>
    fieldsToInclude?: string[]
    params?: MaybeRef<ParametersGetAllUnpaginated>
  }) => ({
    queryKey: [
      workspaceId,
      ...(fieldsToInclude ? [fieldsToInclude.sort()] : []),
      ...(params ? [params] : []),
    ],
  }),

  sharedWithMeUnpaginated: ({
    workspaceId,
    fieldsToInclude = [],
    params,
  }: {
    workspaceId: MaybeRef<string>
    fieldsToInclude?: string[]
    params?: MaybeRef<ParametersGetAllUnpaginated>
  }) => ({
    queryKey: [
      workspaceId,
      ...(fieldsToInclude ? [fieldsToInclude.sort()] : []),
      ...(params ? [params] : []),
    ],
  }),

  byId: ({
    id,
    fieldsToInclude = [],
    workspaceId,
  }:
  {
    id: MaybeRef<string>
    fieldsToInclude?: string[]
    workspaceId?: MaybeRef<string>
  }) => ({
    queryKey: [
      id,
      workspaceId,
      ...(fieldsToInclude ? [fieldsToInclude.sort()] : []),
    ],
  }),
})

const all = <T extends IncludableField[] = []>(
  {
    params,
    fieldsToInclude,
    useQueryOptions = {},
  }: {
    params?: MaybeRef<ParametersGetAll> | undefined
    fieldsToInclude?: T
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  } = {},
) => {
  const { getAll } = useFetchFolder()
  const { activeWorkspaceId } = useAuth()

  return useQuery(
    {
      ...queryKeys.all({
        workspaceId: activeWorkspaceId as MaybeRef<string>,
        fieldsToInclude,
        params,
      }),
      queryFn: () => getAll({
        params: unref(params),
        workspaceId: unref(activeWorkspaceId) ?? '',
        fieldsToInclude: fieldsToInclude ?? [],
      }),
      enabled: () => !!unref(activeWorkspaceId),
      staleTime: DEFAULT_STALE_TIME,
      ...useQueryOptions,
    },
  )
}

const allUnpaginated = <T extends IncludableField[] = []>({
  params,
  fieldsToInclude,
  workspaceId,
  useQueryOptions = {},
}: {
  params?: MaybeRef<ParametersGetAllUnpaginated>
  fieldsToInclude?: T
  workspaceId?: MaybeRef<string | undefined>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getAllUnpaginated } = useFetchFolder()
  const { activeWorkspaceId } = useAuth()

  const _workspaceId = computed(() => unref(workspaceId) || activeWorkspaceId.value || '')

  return useQuery({
    ...queryKeys.allUnpaginated({
      workspaceId: _workspaceId,
      fieldsToInclude,
      params,
    }),
    queryFn: () => getAllUnpaginated({
      params: unref(params),
      workspaceId: unref(_workspaceId) ?? '',
      fieldsToInclude: fieldsToInclude ?? [],
    }),
    enabled: () => !!unref(_workspaceId),
    staleTime: DEFAULT_STALE_TIME,
    ...useQueryOptions,
  })
}

const sharedWithMeUnpaginated = <T extends IncludableField[] = []>({
  params,
  fieldsToInclude,
  workspaceId,
  useQueryOptions = {},
}: {
  params?: MaybeRef<ParametersGetAllSharedWithMeUnpaginated>
  fieldsToInclude?: T
  workspaceId?: MaybeRef<string | undefined>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getSharedWithMeUnpaginated } = useFetchFolder()
  const { activeWorkspaceId } = useAuth()

  const _workspaceId = computed(() => unref(workspaceId) || activeWorkspaceId.value || '')

  return useQuery({
    ...queryKeys.sharedWithMeUnpaginated({
      workspaceId: _workspaceId,
      fieldsToInclude,
      params,
    }),
    queryFn: () => getSharedWithMeUnpaginated({
      params: unref(params),
      workspaceId: unref(_workspaceId),
      fieldsToInclude: fieldsToInclude ?? [],
    }),
    enabled: () => !!unref(activeWorkspaceId),
    staleTime: DEFAULT_STALE_TIME,
    ...useQueryOptions,
  })
}

const byId = <T extends IncludableField[] = []>(
  {
    id,
    fieldsToInclude,
    workspaceId,
    useQueryOptions = {},
  }:
  {
    id: MaybeRef<string>
    fieldsToInclude?: T
    workspaceId?: MaybeRef<string | undefined>
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  },
) => {
  const { getById } = useFetchFolder()
  const { activeWorkspaceId } = useAuth()

  const _workspaceId = computed(() => unref(workspaceId) || activeWorkspaceId.value || '')

  return useQuery({
    ...queryKeys.byId({
      id,
      fieldsToInclude,
      workspaceId: _workspaceId,
    }),
    queryFn: () => getById({
      id: unref(id),
      fieldsToInclude,
      workspaceId: unref(_workspaceId),
    }),
    enabled: () => !!unref(_workspaceId),
    staleTime: DEFAULT_STALE_TIME,
    select: ({ data }) => data,
    ...useQueryOptions,
  })
}

export const useQueryFolder = ({
  all,
  allUnpaginated,
  sharedWithMeUnpaginated,
  byId,
})
