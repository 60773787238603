import type { Query, QueryKey } from '@tanstack/vue-query'

// type copied from @tanstack/vue-query/build/legacy/types
// eslint-disable-next-line ts/no-unsafe-function-type
type MaybeRefDeep<T> = MaybeRef<T extends Function ? T : T extends object ? {
  [Property in keyof T]: MaybeRefDeep<T[Property]>;
} : T>

export const getRelationalPredicate = (type: string) => ({ queryKey }: Query<unknown, Error, unknown, QueryKey>) =>
  queryKey.some(
    (keyElement: any) =>
      keyElement
      && Array.isArray(keyElement.fieldsToInclude)
      && keyElement.fieldsToInclude.some((field: string) => field.split('.').includes(type)),
  )

export const useQueryClientUtils = () => {
  const queryClient = useQueryClient()

  const invalidateQueries = (queryKeys: MaybeRefDeep<QueryKey | undefined>[]) =>
    queryKeys.forEach(queryKey => queryClient.invalidateQueries({ queryKey }))

  const cancelQueries = (queryKeys: MaybeRefDeep<QueryKey | undefined>[]) =>
    queryKeys.forEach(queryKey => queryClient.cancelQueries({ queryKey }))

  const removeQueries = (queryKeys: MaybeRefDeep<QueryKey | undefined>[]) =>
    queryKeys.forEach(queryKey => queryClient.removeQueries({ queryKey }))

  /**
   * Invalidates the cache based on a specified data type within the relational caching system.
   * @param type - The type of cache data to be invalidated.
   */
  const invalidateRelationalCacheDataByType = (type: string) => queryClient.invalidateQueries({
    predicate: getRelationalPredicate(type),
  })

  /**
   * Cancels requests based on a specified data type within the relational caching system.
   * @param type - The type of cache data to be invalidated.
   */
  const cancelRelationalRequestsByType = (type: string) => queryClient.invalidateQueries({
    predicate: getRelationalPredicate(type),
  })

  return {
    removeQueries,
    cancelQueries,
    invalidateQueries,
    cancelRelationalRequestsByType,
    invalidateRelationalCacheDataByType,
  }
}
