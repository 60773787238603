import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { FetchError } from 'ofetch'
import { type ParametersGetAll, responseDataTypeName, useFetchRecommendation } from './useFetchRecommendation'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({ workspaceId, params }: { workspaceId: MaybeRef<string>, params?: MaybeRef<ParametersGetAll> }) => ({
    queryKey: [{ workspaceId, params }],
  }),
})

const all = ({ workspaceId, params, useQueryOptions }: {
  workspaceId: MaybeRef<string>
  params?: MaybeRef<ParametersGetAll>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getAll } = useFetchRecommendation()

  return useQuery({
    queryKey: queryKeys.all({ workspaceId, params }).queryKey,
    queryFn: () => getAll({ workspaceId: unref(workspaceId), params: unref(params) }),
    retry: (_, error: FetchError) => !!error.status && error.status !== 409,
    retryOnMount: false, // does not allow a method, so we can only fall back to the boolean
    refetchOnWindowFocus: false,
    ...useQueryOptions,
  })
}

export const useQueryRecommendation = {
  all,
}
