import { createQueryKeys } from '@lukemorales/query-key-factory'
import { responseDataTypeName, useFetchTemplateCategory } from './useFetchTemplateCategory'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

type Country = App.Internals.Enums.Country

export const queryKeys = createQueryKeys(responseDataTypeName, {
  byId: ({
    id,
    locale,
    country,
  }: {
    id: MaybeRef<string>
    locale: MaybeRef<App.Internals.Enums.Language>
    country: MaybeRef<Country>
  }) => ({
    queryKey: [country, id, locale],
  }),
  all: ({
    locale,
    country,
  }: {
    locale: MaybeRef<App.Internals.Enums.Language>
    country: MaybeRef<Country>
  }) => ({
    queryKey: [country, locale],
  }),
})

const byId = ({
  id,
  useQueryOptions = {},
}: {
  id: MaybeRef<string>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const queryClient = useQueryClient()
  const { language } = useLanguage()
  const { getById } = useFetchTemplateCategory()
  const { activeWorkspaceId, country } = useAuth()

  type GetAllType = ReturnType<ReturnType<typeof useFetchTemplateCategory>['getAll']>

  return useQuery({
    ...queryKeys.byId({ id, country: country as MaybeRef<Country>, locale: language }), // DEV-5806 remove casting
    queryFn: () => getById({
      id: unref(id),
      workspaceId: unref(activeWorkspaceId) ?? undefined,
    }),
    enabled: () => !!unref(activeWorkspaceId) && !!unref(country),
    // pull from the list cache
    initialData: (): Awaited<ReturnType<typeof getById>> | undefined => {
      const data = queryClient.getQueryData<Awaited<GetAllType>>(
        queryKeys.all({
          country: country as MaybeRef<Country>,
          locale: language,
        }).queryKey, // DEV-5806 remove casting
      )?.data.find(category => category.id === unref(id))

      return data ? { data } : undefined
    },
    ...useQueryOptions,
  })
}

const all = ({
  useQueryOptions = {},
}: {
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getAll } = useFetchTemplateCategory()
  const { language } = useLanguage()
  const { activeWorkspaceId, country } = useAuth()

  return useQuery({
    ...queryKeys.all({
      country: country as MaybeRef<Country>,
      locale: language,
    }), // DEV-5806 remove casting
    queryFn: () => getAll({ workspaceId: unref(activeWorkspaceId) ?? undefined }),
    enabled: () => !!unref(activeWorkspaceId) && !!unref(country),
    select: ({ data }) => data,
    ...useQueryOptions,
  })
}

export const useQueryTemplateCategory = {
  byId,
  all,
}
