import { useFetchWithDefaults } from '../../useFetchWithDefaults'
import type { WorkspaceIdentifier } from '../../types'
import type { PriceListAddonPrice, PriceListItemPrice } from '../../price-list/useFetchPriceList'
import type { Response } from '~/types/api/v2'

export interface EstimateLineData {
  id: string
  type: 'addon' | 'plan'
  description: string
  quantity: number
  unitAmount: number
}

export interface EstimateActiveCoupon {
  id: string
  amount: number
  description?: string
}

interface EstimateDiscount {
  type: 'excess_payments' | 'creditsApplied' | string & {}
  amount: number
}

export interface Estimate {
  amountTotal: number
  amountDue: number
  taxAmount: number
  taxRate: number
  nextBillingDate: number
  currency: string
  activeCoupon?: EstimateActiveCoupon
  discounts: EstimateDiscount[]
  lines: EstimateLineData[]
}

export interface EstimatePayload {
  planPriceId: PriceListItemPrice['id']
  coupon?: string
  addonPriceId?: PriceListAddonPrice['price_id']
  addonQuantity?: number
}

export const responseDataTypeName = 'scheduled_estimate'

export const useFetchSubscriptionEstimate = () => {
  const getUrl = (workspaceId: string) => `/me/companies/${workspaceId}/subscription/estimate`
  const getScheduledUrl = (subscriptionId: string) => `/v2/subscriptions/${subscriptionId}/schedule/estimate`

  const { post } = useFetchWithDefaults()

  const getEstimate = ({
    workspaceId,
    payload,
  }: WorkspaceIdentifier & {
    payload: EstimatePayload
  }) =>
    post<Estimate>({
      url: getUrl(workspaceId),
      workspaceId,
      fetchOptions: {
        body: {
          plan: payload.planPriceId,
          coupon: payload.coupon || null,
          ...(payload.addonPriceId ? { addons: [{ id: payload.addonPriceId, quantity: payload.addonQuantity || 1 }] } : {}),
        },
      },
    })

  const getScheduleEstimate = ({
    workspaceId,
    subscriptionId,
    payload,
  }: WorkspaceIdentifier & {
    subscriptionId: string
    payload: App.Data.Payloads.Subscription.UpdateSubscriptionSchedulePayload
  }) => post<Response<App.Data.Subscription.EstimateData>>({
    url: getScheduledUrl(subscriptionId),
    workspaceId,
    fetchOptions: {
      body: {
        action: payload.action,
        ...(payload.plan_price_id ? { plan_price_id: payload.plan_price_id } : {}),
        ...(payload.addon_ids ? { addon_ids: payload.addon_ids } : {}),
      },
    },
  })

  return {
    getEstimate,
    getScheduleEstimate,
  }
}
