import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type IncludableField, type ParametersGetAll, responseDataTypeName, useFetchReminder } from './useFetchReminder'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({ workspaceId, fieldsToInclude, params }: { workspaceId: MaybeRef<string>, fieldsToInclude?: MaybeRef<IncludableField[]>, params?: MaybeRef<ParametersGetAll> }) => ({
    queryKey: [
      workspaceId,
      {
        ...(unref(fieldsToInclude) ? { fieldsToInclude: unref(fieldsToInclude)?.sort() } : {}),
        ...(unref(params) ? { params: unref(params) } : {}),
      },
    ],
  }),
})

const all = <T extends IncludableField[] = []>({
  fieldsToInclude,
  params,
  useQueryOptions = {},
}: {
  fieldsToInclude?: T
  params?: MaybeRef<ParametersGetAll>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getAll } = useFetchReminder()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    queryKey: queryKeys.all({
      workspaceId: activeWorkspaceId.value ?? '',
      fieldsToInclude,
      params,
    }).queryKey,
    queryFn: () => getAll({
      workspaceId: unref(activeWorkspaceId) ?? '',
      fieldsToInclude: fieldsToInclude ?? [],
      params: unref(params),
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}
export const useQueryReminder = {
  all,
}
