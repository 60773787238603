import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { Response } from '~/types/api/v2'

export const responseDataTypeName: App.Data.BatchApi.BatchApiResponseData['_type'] = 'batch_api_response'

// payload types are rewritten from App.Data.Payloads.Documents.BatchApi.BatchApiPayload

export interface BatchApiPayloadAddTags {
  mutation: 'add_tags'
  value: string[]
  documents: string[]
}

export interface BatchApiPayloadRemoveTags {
  mutation: 'remove_tags'
  value: string[]
  documents: string[]
}

export interface BatchApiPayloadMoveToFolder {
  mutation: 'move_to_folder'
  value: string
  documents?: string[]
  folders?: string[]
}

export interface BatchApiPayloadTrash {
  mutation: 'trash'
  documents?: string[]
  folders?: string[]
}

export interface BatchApiPayloadExportDocumentData {
  mutation: 'export_document_data'
  export_types: string[]
  documents: string[]
}

export type BatchApiPayloadByMutation = {
  [K in App.Internals.Enums.BatchApiMutation]:
  K extends 'add_tags' ? BatchApiPayloadAddTags :
    K extends 'remove_tags' ? BatchApiPayloadRemoveTags :
      K extends 'move_to_folder' ? BatchApiPayloadMoveToFolder :
        K extends 'trash' ? BatchApiPayloadTrash :
          K extends 'copy_to_workspace' ? App.Data.Payloads.Documents.BatchApi.BatchMigratePayload :
            K extends 'export_document_data' ? App.Data.Payloads.Documents.BatchApi.BatchExportDocumentDataPayload :
              'NOT_IMPLEMENTED'
}

export interface BatchMutationVariables<Mutation extends App.Internals.Enums.BatchApiMutation> {
  workspaceId: string
  payload: BatchApiPayloadByMutation[Mutation]
}

export const useFetchBatch = () => {
  const url = '/v2/batches'
  const createMutationFn = <Mutation extends App.Internals.Enums.BatchApiMutation>() => ({
    workspaceId,
    payload,
  }: BatchMutationVariables<Mutation>) => {
    const { post } = useFetchWithDefaults()
    return post<Response<App.Data.BatchApi.BatchApiResponseData>>({
      url,
      workspaceId,
      fetchOptions: {
        body: payload,
      },
    })
  }

  const getById = ({
    id,
    workspaceId,
  }: {
    id: string
    workspaceId: string
  }) => {
    const { get } = useFetchWithDefaults()
    return get<Response<App.Data.BatchApi.BatchData>>({
      url: `${url}/${id}`,
      workspaceId,
    })
  }

  return {
    addTags: createMutationFn<'add_tags'>(),
    removeTags: createMutationFn<'remove_tags'>(),
    moveToFolder: createMutationFn<'move_to_folder'>(),
    trash: createMutationFn<'trash'>(),
    moveToWorkspace: createMutationFn<'move_to_workspace'>(),
    copyToWorkspace: createMutationFn<'copy_to_workspace'>(),
    requestDocumentDataExport: createMutationFn<'export_document_data'>(),
    getById,
  }
}
