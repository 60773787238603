import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'
import { useApiV2FetchFactory } from '../useApiV2FetchFactory'
import type { WithNegated } from '~/types/util/string'
import type { CollectionRequestPaginationParams, CollectionResponse, IncludedFieldDependentResponse, Response,
} from '~/types/api/v2'

type DocumentData = App.Data.Document.DocumentData
type DocumentSearchPayload = App.Data.Payloads.Documents.Search.DocumentSearchPayload
type DuplicateDocumentPayload = App.Data.Payloads.Documents.DuplicateDocumentPayload

export const responseDataTypeName: DocumentData['_type'] = 'document'

export const url = '/v2/documents'
export const getUrlById = (documentId: string) => `${url}/${documentId}`

export type SortCriterion =
  'created_at' |
  'updated_at' |
  'archive_status' |
  'contains_pii' |
  'title' |
  'document.contract.stage' |
  'document.contract.name' |
  'document.termsAndConditions.date' |
  'document.termsAndConditions.signing_date' |
  'document.termsAndConditions.term_end_date' |
  'document.termsAndConditions.effective_date'

export type IncludableField =
  'attachments' |
  'attachments.file' |
  'folder' |
  'folder.own_permissions' |
  'owner' |
  'contract' |
  'contract.user' |
  'contract.signing_settings' |
  'contract.template' |
  'contract.signatures' |
  'workpace' |
  'languages' |
  'document_types' |
  'document_types.translations' |
  'jurisdictions' |
  'parties.address' |
  'parties.external_references' |
  'string_values' |
  'string_values.property' |
  'numeric_values' |
  'numeric_values.property' |
  'boolean_values.property' |
  'date_values' |
  'date_values.property' |
  'boolean_values' |
  'external_references' |
  'tags' |
  'file' |
  'terms_and_conditions' |
  'retention' |
  'reminders' |
  'reminders.schedules' |
  'reminders.recipients' |
  'summaries'

export type DocumentIdentifier = WorkspaceIdentifier & {
  documentId: string
}
export type DocumentMutationParams<TPayload> = {
  payload: TPayload
} & DocumentIdentifier
interface SortParameters {
  sort?: WithNegated<SortCriterion>[]
}
export type ParametersGetAll = {
  filters?: DocumentSearchPayload['filters']
} & SortParameters & CollectionRequestPaginationParams

export const useFetchDocument = () => {
  const { post } = useFetchWithDefaults()

  type DynamicResponse<TFieldsToInclude extends string[]> = IncludedFieldDependentResponse<
    DocumentData,
    TFieldsToInclude,
    IncludableField
  >

  const { createGetById } = useApiV2FetchFactory<DocumentData, IncludableField>(url)

  const getAll = <TFieldsToInclude extends IncludableField[] = []>({
    params,
    workspaceId,
    fieldsToInclude,
  }: WorkspaceIdentifier & { params?: ParametersGetAll, fieldsToInclude?: TFieldsToInclude },
  ) => {
    const { filters, ...paramsWithoutFilters } = params || {}

    return post<CollectionResponse<DynamicResponse<TFieldsToInclude>>>(
      {
        url: `${url}/search`,
        workspaceId,
        fetchOptions: {
          params: {
            sort: 'title',
            ...paramsWithoutFilters,
            ...(fieldsToInclude?.length ? { include: fieldsToInclude } : {}),
          },
          body: {
            filters,
          },
        },
      },
    )
  }

  const getById = createGetById()

  const duplicate = ({
    payload,
    documentId,
    workspaceId,
  }: DocumentMutationParams<DuplicateDocumentPayload>,
  ) => post<Response<DocumentData>>({
    url: `${getUrlById(documentId)}/duplicate`,
    workspaceId,
    fetchOptions: {
      body: payload,
    },
  })

  const sendViaEmail = ({
    payload,
    documentId,
    workspaceId,
  }: DocumentMutationParams<App.Data.Payloads.Documents.SendToEmailPayload>,
  ) => post<void>({
    url: `${getUrlById(documentId)}/share/email`,
    workspaceId,
    fetchOptions: {
      body: payload,
    },
  })

  return {
    getAll,
    getById,
    duplicate,
    sendViaEmail,
  }
}
