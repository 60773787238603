import { useFetchWithDefaults } from '~/composables/api/useFetchWithDefaults'
import type { CollectionResponse } from '~/types/api/v2'

type LegalScoreCategoryData = App.Data.LegalScore.CategoryData
export const responseDataType: LegalScoreCategoryData['_type'] = 'legal_score.category'

export const useFetchLegalScoreCategory = () => {
  const url = '/v2/legal-score/categories'

  const { get } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: { workspaceId: string }) =>
    get<CollectionResponse<LegalScoreCategoryData>>({
      url,
      workspaceId,
    })

  return { getAll }
}
