import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { Response } from '~/types/api/v2'

export const responseDataTypeName = 'recommendation'

export interface ParametersGetAll {
  workspaceId: string
  params: {
    per_page: number
    page: number
  }
}

export type RecommendationData = App.Data.Recommendation.CreateContractRecommendationData
interface Meta { // The use of meta like this is an exception in the backend.
  meta?: {
    reason?: 'no-finalized-contracts'
  }
}

export const useFetchRecommendation = () => {
  const { get } = useFetchWithDefaults()
  const baseUrl = '/v2/recommendations'

  const getAll = ({
    workspaceId,
    params,
  }: {
    workspaceId: string
    params?: ParametersGetAll
  }) => get<Response<RecommendationData[]> & Meta>({
    url: baseUrl,
    workspaceId,
    fetchOptions: { params },
  })

  return {
    getAll,
  }
}
