import { useFetchWithDefaults } from '~/composables/api/useFetchWithDefaults'
import type { CollectionResponse, IncludedFieldDependentResponse } from '~/types/api/v2'

type ReminderData = App.Data.Document.Reminders.ReminderData
export const responseDataTypeName: ReminderData['_type'] = 'reminder'

export type IncludableField = 'schedules' | 'recipients' | 'document'

export interface ParametersGetAll {
  'filter[user_id]'?: string
  'filter[email]'?: string
  'filter[after]'?: string
}

export const useFetchReminder = () => {
  const { get } = useFetchWithDefaults()

  type DynamicResponse<TFieldsToInclude extends string[]> =
    IncludedFieldDependentResponse<
      ReminderData,
      TFieldsToInclude,
      IncludableField
    >

  const getAll = <TFieldsToInclude extends IncludableField[] = []>({
    workspaceId,
    params,
    fieldsToInclude,
  }: { workspaceId: string, fieldsToInclude: TFieldsToInclude, params?: ParametersGetAll }) =>
    get<CollectionResponse<DynamicResponse<TFieldsToInclude>>>({
      url: '/v2/reminders',
      workspaceId,
      fetchOptions: {
        params: { ...params, include: fieldsToInclude ?? [] },
      },
    })

  return {
    getAll,
  }
}
